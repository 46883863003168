<template>
  <section class="Category">
    
    <div class="page-header mb-0">
      <h3 class="page-title pt-2">Edit Tag</h3>
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb p-0">
          <li class="breadcrumb-item">
            <router-link to="/catalog/tag" class="text-primary">Tag</router-link>
          </li>
          <li class="breadcrumb-item active" aria-current="page">Edit Tag</li>
        </ol>
      </nav>
    </div>

    <form class="pt-2" @submit.prevent="submitEdit" v-if="tag">
      <div class="row pt-2">
        <div class="col-lg-12">
          <div class="card mb-3">
            <div class="card-body">
              <div class="form-group mb-1">
                <label class="fs-12 col-form-label" for="name">Name</label>
                <input
                  type="text"
                  v-model="tag.name"
                  class="form-control"
                  :class="{ 'is-invalid': submitted && $v.tag.name.$error }"
                />
                <div v-if="submitted && $v.tag.name.$error" class="invalid-feedback">
                  Name is required
                </div>
              </div>

              <div class="form-group">
                <label class="fs-12 col-form-label" for="sort">Sort</label>
                <input type="number" v-model="tag.sort" class="form-control" />
              </div>

              <div class="form-group">
                <label>Status</label>
                <div class="form-group mb-0">
                <toggle-button v-model="tag.active" :labels="{ checked: 'true', unchecked: 'false' }" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-12">
          <button class="btn btn-primary float-right mt-3">Update</button>
        </div>
      </div>
    </form>
  </section>
</template>

<script>

import { catalogApi } from "../../../api";
import { required } from "vuelidate/lib/validators";

export default {
  name: "EditTag",
  data() {
    return {
      tag: null,
      submitted: false,
      
    };
  },
  validations: {
    tag: {
      name: { required },
    },
  },
  mounted() {
    this.getTag();
  },
  methods: {
    async getTag() {
      
      const { data } = await catalogApi.getTagById(this.$route.params.id);
      const tagData = data.data.data[0];

      this.tag = {
        ...tagData,
        active: tagData.active === 1 ? true : false,
      };
      
    },
    async submitEdit() {
      this.submitted = true;
      this.$v.$touch();

      if (this.$v.$invalid) return;

      
      const updatedTag = { ...this.tag, active: this.tag.active ? 1 : 0 };

      const response = await catalogApi.updateTag(this.$route.params.id, updatedTag);
      

      if (response.status === 200) {
        this.$swal({ toast: true, position: "top-end", showConfirmButton: false, text: response.messages, type: "success", timer: 3000 });
        this.$router.push("/catalog/tag");
      } else {
        this.$swal({ title: "Error", text: response.messages, type: "error" });
      }
    },
  },
};
</script>
